.App {
  text-align: center;
  background: rgb(245, 245, 248);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.MuiSelect-select, .highcharts-title, .highcharts-legend-item text, .MuiMenuItem-root {
  text-transform: capitalize;
}


.MuiInputLabel-root {
  text-align: left;
}

.playercard a:-webkit-any-link, .playergamecard a:-webkit-any-link, .lastgamecard a:-webkit-any-link {
  text-decoration: none !important;
}


.navdrawer .MuiListItem-root {
  cursor: pointer;
}

.MuiChip-deleteIcon {
  font-size: 0.9rem !important;
  line-height: 1 !important;
  color: black !important;
  margin: 0 !important;
  margin-right: 0.45rem !important;
}

.MuiSelect-select, .MuiTextField-root, .MuiOutlinedInput-root {
  background: white !important;
}

.MuiInputLabel-root {
  color: black !important;
}

.featured-card:hover, .playercard:hover, .hoverme:hover, .playergamecard:hover {
  /* background-color: green !important; */
  transform: scale(1.05);
  transition: transform 250ms;
  animation: blink .3s linear 2;
}

a:-webkit-any-link {
  text-decoration: none!important;
}
@media (min-width: 600px) {
  #singleplayerscreen .gamesheader {
    padding-top: 5rem !important;
  }
}

@media (max-width: 600px) {
  #singleplayerscreen .gamesheader {
    padding-top: 7rem !important;
  }
}

.no-padding-cell {
  padding: 0 !important;
}

@media (max-width: 600px) {
  .no-padding-header {
    min-width: 120px !important;
  }
  .no-padding-cell {
    padding: 0 !important;
    min-width: 120px !important;
  }
}